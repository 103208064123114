<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('COMPANY.TITLE_NEW') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'company' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group id="input-group-3" :label="`${$t('FORM.LOGO')}`" label-for="input-3" label-cols-md="2" label-align-md="right">
              <FileImage
                id="input-3"
                v-model="$v.form.logo.$model"
                accept="image/*"
                aria-describedby="input-3-feedback"
                @input="clearServerError('logo')"
              />
              <b-form-invalid-feedback id="input-3-feedback" :class="{'d-block': validateState('logo') === false}">
                <template v-if="serverErrors.logo">{{ serverErrors.logo[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.LOGO') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-1" :label="`${$t('FORM.NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                trim
                @input="clearServerError('name')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-2" :label="`${$t('FORM.DOMAIN')}`" label-for="input-2" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-2"
                v-model="$v.form.domain.$model"
                :state="validateState('domain')"
                :placeholder="$t('PLACEHOLDER.DOMAIN')"
                @input="clearServerError('domain')"
                aria-describedby="input-2-feedback"
              />
              <b-form-invalid-feedback id="input-2-feedback">
                <template v-if="serverErrors.domain">{{ serverErrors.domain[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.DOMAIN') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-4" :label="`${$t('FORM.PHONE')}`" label-for="input-4" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-4"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                :placeholder="$t('PLACEHOLDER.PHONE')"
                @input="clearServerError('phone')"
                aria-describedby="input-4-feedback"
              />
              <b-form-invalid-feedback id="input-4-feedback">
                <template v-if="serverErrors.phone">{{ serverErrors.phone[0] }}</template>
                <template v-else-if="!$v.form.phone.alpha">{{ $t('VALIDATION.INVALID', { name: $t('FORM.PHONE') }) }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PHONE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-5" :label="`${$t('FORM.DESCRIPTION')}`" label-for="input-5" label-cols-md="2" label-align-md="right">
              <Textarea
                id="input-5"
                v-model="$v.form.description.$model"
                :state="validateState('description')"
                :placeholder="$t('PLACEHOLDER.DESCRIPTION')"
                @input="clearServerError('description')"
                aria-describedby="input-5-feedback"
              />
              <b-form-invalid-feedback id="input-5-feedback">
                <template v-if="serverErrors.description">{{ serverErrors.description[0] }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="info" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { validationMixin } from 'vuelidate'
import { required, helpers } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/admin/companyRepository'
import RepoFile from '@/core/repository/company/fileRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../content/Card'
import InputForm from '../../../../components/forms-items/input'
import serverVuelidate from '../../../../mixins/serverVuelidate'
import { TEL } from '../../../../helpers/validation'
import Textarea from '../../../../components/forms-items/Textarea'
import FileImage from '../../../../components/forms-items/FileImage'

export default {
  name: 'CompanyNew',
  components: {
    FileImage,
    Textarea,
    InputForm,
    KTCard, 
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      file: null,
      form: {
        name: '',
        domain: '',
        description: '',
        phone: '',
        logo: null,
      },
      validations: {
        form: {
          name: { required },
          domain: { required },
          logo: { required },
          description: {},
          phone: {
            required,
            alpha: helpers.regex('alpha', TEL), 
          },
        },
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('COMPANY.TITLE_NEW') }])
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
        domain: '',
        phone: '',
        description: '',
        logo: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    addFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      return RepoFile.post(formData)
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const { data } = await this.addFile(this.form.logo)
      const obj = { ...this.form }
      obj.logo = data.payload.uuid
      Repo.post(obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.CREATED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        this.resetForm()
        setTimeout(() => this.$router.push({ name: 'company' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
